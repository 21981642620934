


//======== 【Prefix】 ========
$css-prefix: yzh;
$menu-prefix-cls: ant-menu;
$layout-prefix-cls: ant-layout;
// css变量
:root {
    --primary-color: #F76700;
    --bg-active-color: rgba(0,0,0,0.5);
    --bg-color: #3f3d3c;
    --header-bg-color: #fff;
    --text-color: #ccc;
    --header-text-color: #333;
    --text-light-color: #fff;
}

// 主题
$menu-dark-color: var(--text-color);
$menu-dark-bg: var(--bg-color);
$menu-item-padding: 20px;
$menu-dark-highlight-color: var(--text-light-color);
$menu-dark-item-hover-color: var(--text-light-color);
$menu-dark-item-active-bg: var(--bg-active-color);
$menu-dark-item-hover-bg: var(--bg-active-color);
$menu-dark-inline-submenu-bg: var(--bg-active-color);
$menu-dark-arrow-color: var(--text-color);
$menu-dark-selected-item-icon-color: var(--text-light-color);
$menu-dark-selected-item-text-color: var(--text-light-color);
$disabled-color-dark: var(--text-color);

$header-dark-color: var(--header-text-color);
$header-dark-bg: var(--header-bg-color);

$border:#e8e8e8;
$default:#666;
$black:#333;
$color999:#999;
$theme:var(--primary-color);
$aside-bg-color:#3f3d3c;
$bg-color:#f5f5f5;
$bg-body:#f8f8f8;

//------字体 
//-----中文：微软雅黑  ，英文：Arial
$font-ch:"微软雅黑";
$font:"Arial";

//内容最小宽度
$cont-width:1200px;

//模块间距
$box-spacing1:20px;
$box-spacing2:30px;

//模块圆角
$box-radius:4px;
// 重要
$primary:var(--primary-color);
$success:#61b93f;
$warning:#ff9900;
$danger:#f54040;
$ff8:#fef1e8;

$info:#6579f9;
$white:#fff;
$fff:#fff;
$c-333:#333;

// 一般
$c-3f:#3f3d3c;   // 主要用在导航背景
$c-666:#666;
$c-999:#999;
$c-ccc:#ccc;

// 较弱
$e8:#e8e8e8;
$f8:#f8f8f8;

//字体大小
$fontS28:28px;
$fontS18:18px;
$fontS14:14px;
$fontS12:12px;

// 行高
$lh34:34px;

// $primary-color: var(--primary-color);










.theme-container {
    .others,.img {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        &-item {
            margin-bottom: 20px;
            margin-right: 30px;
            background-color: $e8;
            border: 1px solid transparent;
            &.active,
            &:hover {
                border-color: $primary;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
    .others {
        margin-top: 30px;
        &-item {
            border: none;
            align-items: center;
            justify-content: space-between;
            width: 45%;
            margin-right: 0;
            flex-wrap: wrap;
            display: flex;
            background-color: $white;
            .name {
                flex-basis: 70px;
                text-align: right;
            }
        }
    }
    .color-picker-btn {
        padding: 4px;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
        display: inline-block;
        cursor: pointer;
        border: none;
        line-height: 32px;
        height: 32px;
        width: 180px;
        vertical-align: middle
    }
    .custom-color {
        &-title {
            font-size: 16px;
            color: #000;
            font-weight: bold;
            margin-bottom: 10px;
        }
        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            span {
                flex: 1;
                text-align: right;
            }
            .color-picker {
                flex: 2;
                text-align: right;
                &-main {
                    position: absolute;
                    top: 80px;
                }
            }
        }
    }
}