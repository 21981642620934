html{font-family:sans-serif;-webkit-text-size-adjust:100%;font-size:48px}
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block}
audio,canvas,progress,video{display:inline-block;vertical-align:baseline}
address,caption,cite,code,dfn,em,th,var,optgroup,i,button,input,optgroup,select,textarea{
    font:inherit;margin:0;color:inherit
}
button{overflow:visible;text-transform:none}
audio:not([controls]){display:none;height:0}
[hidden],template{display:none}
ul,li{list-style:none}
abbr[title]{border-bottom:1px dotted}
b,strong{font-weight:700}
dfn{font-style:italic}
h1{margin:.67em 0}
mark{color:#000;background:#ff0}
small{font-size:80%}
sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sup{top:-.5em}
sub{bottom:-.25em}
img{border:0;outline:0;vertical-align:middle;max-width:100%}
svg:not(:root){overflow:hidden}
figure{margin:1em 40px}
hr{box-sizing:content-box;height:0}
pre{overflow:auto}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}
button{overflow:visible}
button,select{text-transform:none}
button,html input[type=button],
input[type=reset],
input[type=submit]{cursor:pointer;-webkit-appearance:button}
button[disabled],html input[disabled]{cursor:default}
input{line-height:normal}
input[type=checkbox],input[type=radio]{box-sizing:border-box;padding:0}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{height:auto}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration{-webkit-appearance:none}
fieldset{margin:0 2px;padding:.35em .625em .75em;border:1px solid silver}
legend{padding:0;border:0}textarea{overflow:auto;resize:none}
optgroup{font-weight:700}
table{border-spacing:0;border-collapse:collapse}
td,th{padding:0}
*{padding:0;margin:0;-webkit-box-sizing:border-box;box-sizing:border-box;outline:0;-webkit-tap-highlight-color:transparent}
h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;margin-bottom: 0;}
a{background: 0 0;text-decoration:none;color:#666}
a:active,a:hover {outline: 0;}

html.curr,body.curr{ width: 100%; height: 100%; overflow: hidden;}
body.modal-open{position: fixed; width: 100%; overflow: hidden;}

body {
    max-width: 100%;
    margin: 0 auto;
	font-family: 'Microsoft YaHei';
    font-size: 24px;
    line-height: 1.2;
    background-color: $f8;
    -webkit-overflow-scrolling: touch;
    // overflow: hidden;
}
html,
body {height: 100%;}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
    // border:none;
}
textarea {
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: auto;
  resize: none;
//   font: 100% tahoma, \5b8b\4f53, arial;
}
::-webkit-scrollbar {
    width: 10px;/*滚动条宽度*/
    height: 10px;
 }
 
 ::-webkit-scrollbar-thumb {
    border-radius: 5px;/*滚动条圆角*/
   -webkit-border-radius: 5px;/*兼容圆角属性*/
   background-color: #c3c3c3;/*滚动条颜色*/
 }
 ::-webkit-scrollbar-track {
    background-color: transparent;/*滚动条的背景颜色*/
}

html {
    * {
      scrollbar-color:#c3c3c3 transparent;
      scrollbar-width: thin;
    }
}
