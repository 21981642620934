@charset "UTF-8";
.txt-line{ text-decoration: line-through;} // 文字下划线
/*对齐，浮动*/   
.tc{ text-align:center;}
.tr{ text-align: right;}
.tl{ text-align: left;}
.fl{ float:left;}
.fr{  float: right;}
.clearfix:before,.clearfix:after{content:""; display:table;} 
.clearfix:after{clear:both;}

/*文字超出隐藏*/
.ellipsis{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.line-clamp1,
.line-clamp2,
.line-clamp3,
.line-clamp4,
.line-clamp5{overflow: hidden;text-overflow: ellipsis; display: -webkit-box;-webkit-box-orient: vertical;}
.line-clamp1{-webkit-line-clamp: 1;}
.line-clamp2{-webkit-line-clamp: 2;}
.line-clamp3{-webkit-line-clamp: 3;}
.line-clamp4{-webkit-line-clamp: 4;}
.line-clamp5{-webkit-line-clamp: 5;}
.yzhvhide{visibility: hidden}
html,body {font-size: 12px;}

.w1200 {
    width: 1200px;
    margin: 0 auto;
}
.row { display: flex }
.column { display: flex; flex-direction: column }
.align-c { align-items: center }
.just-c { justify-content: center; }
.flex-wrap { flex-wrap: wrap }
.col { flex: 1 }
.col-2 { flex: 2 }
.col-3 { flex: 3 }
.col-4 { flex: 4 }
.tc { text-align: center }
.mt10{ margin-top: 10px; }
.mt20{ margin-top: 20px; }
.ml10 { margin-left: 10px }
.mr10 { margin-right: 10px }
.primary{ color: $primary}
.pointer{cursor: pointer}
.ant-tag {
    color: $primary;
    background: #fff;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid $primary;
    cursor:pointer
  
}
.ant-table-cell{
    padding: 16px 8px !important; 
}
.ant-space{
    gap: 0px !important; 
}
.ant-table-thead{
    background-color: #f5f5f5;
    .ant-table-cell{
        padding: 11px!important; 
        height: 20px !important; 
    }

}
//搜索框
.inputbox{
    display: flex;
    border:1px solid #d9d9d9 ;
    padding:0  0 0 15px;
    border-radius: 4px;
    margin-left: -10px;
    label{
        display: block;
        width: 128px;
        height: 32px;
        line-height: 32px;
    }
    .ant-picker{
        border: none;  
        box-shadow: none;
    }
    .ant-select-selector,
    .ant-input{
        border: none;
        height: 32px;
        width: 100%;
        box-shadow: none !important;
    }
    .ant-input-affix-wrapper{
        border: none;
        height: 32px;
        padding: 0;
        width: 100%;
        box-shadow: none;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border: none;
        box-shadow: none;
        text-align: right;
    }
    .ant-select-selection-item{
        text-align: end;
    }
}
.pro-form-query-filter-actions{
    .ant-btn-primary{
        margin-right: 16px;
    }
   
}
.yzh-table-search{
    .ant-pro-form-collapse-button{
        display: none;
    }
    .pro-form-query-filter-actions{
        text-align: left;
        margin-left: -28px;
    }
    .ant-col-offset-12{
        margin: 0;
    }
    .ant-col-offset-6{
        margin: 0;
    }
    .ant-col-offset-18{
        margin: 0;
    }
    .ant-col-offset-4{
        margin: 0;
        width: 25%;
    }
    .ant-col-4 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .ant-btn {
        padding: 4px 12.68px !important;
    }
    
}


.ant-table-thead .ant-table-cell{
    padding: 0px;
}

.ant-table-body{
    // scroll:true
}
::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    min-height: 48px;
    background: #d2d2d2;
    background-clip: padding-box;
    border: 3px solid #fff;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:active {
    background: #888;
    border-width: 2px;
  } 


// .ant-form-item{
//     border:1px solid #d9d9d9 ;
// }
//end