@font-face {
    font-family: 'yzhIcon';  /* project id 1142607 */
    src: url('//at.alicdn.com/t/font_1142607_pnc2isp4eec.eot');
    src: url('//at.alicdn.com/t/font_1142607_pnc2isp4eec.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1142607_pnc2isp4eec.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1142607_pnc2isp4eec.woff') format('woff'),
    url('//at.alicdn.com/t/font_1142607_pnc2isp4eec.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1142607_pnc2isp4eec.svg#yzhIcon') format('svg');
  }

@font-face {
    font-family: 'yzhIconCommon'; /* project id 1167313 */
    src: url('//at.alicdn.com/t/font_1167313_d8wtrnmb1i5.eot');
    src: url('//at.alicdn.com/t/font_1167313_d8wtrnmb1i5.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1167313_d8wtrnmb1i5.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1167313_d8wtrnmb1i5.woff') format('woff'),
    url('//at.alicdn.com/t/font_1167313_d8wtrnmb1i5.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1167313_d8wtrnmb1i5.svg#yzhIconCommon') format('svg');
}

  @font-face {
    font-family: 'yzhIconOperation';  /* project id 1462286 */
    src: url('//at.alicdn.com/t/font_1462286_gguldukoba.eot');
    src: url('//at.alicdn.com/t/font_1462286_gguldukoba.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1462286_gguldukoba.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1462286_gguldukoba.woff') format('woff'),
    url('//at.alicdn.com/t/font_1462286_gguldukoba.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1462286_gguldukoba.svg#yzhIconOperation') format('svg');
  }

.yzh-icon {
    font-family: 'yzhIcon'; /* project id 1142607 */
    font-size: 18px;
}
.yzh-icon-common {
    font-family: 'yzhIconCommon'; /* project id 1167313 */
    font-size: 18px;
}
.yzh-icon-operation {
    font-family: 'yzhIconOperation'; /* project id 1462286 */
    font-size: 18px;
}
.achive-code-icon-logo:before{
    font-family: 'yzhIcon'; /* project id 1142607 */
    content: '\e61e';
    font-size: 12px;
    // color: $c-666;
    margin-right: 10px;
    vertical-align: middle;
}
// 成功
.yzh-icon-success:before {
    font-family: 'yzhIconCommon'; /* project id 1142607 */
    content: '\e639';
    font-size: 25px;
    color: $success;
    margin-right: 10px;
    vertical-align: middle;
}
//警告
.yzh-icon-warning:before {
    font-family: 'yzhIconCommon'; /* project id 1142607 */
    content: '\e637';
    font-size: 25px;
    color: $warning;
    margin-right: 10px;
    vertical-align: middle;
}
//错误/危险
.yzh-icon-danger:before {
    font-family: 'yzhIconCommon'; /* project id 1142607 */
    content: '\e632';
    font-size: 25px;
    color: $danger;
    margin-right: 10px;
    vertical-align: middle;
}
//右下角标
.yzh-icon-angle:before {
    font-family: 'yzhIconCommon'; /* project id 1142607 */
    content: '\e630';
    position: absolute;
    right: -1px;
    bottom: 0;
    font-size: 18px;
    color: $primary;
    vertical-align: middle;
}
//相机
.yzh-icon-camera:before {
    font-family: 'yzhIconCommon'; /* project id 1142607 */
    content: '\e63a';
    font-size: 28px;
    color: $c-999;
    vertical-align: middle;
}
//相机
.yzh-icon-camera-add:before {
    font-family: 'yzhIconCommon'; /* project id 1142607 */
    content: '\e63a';
    font-size: 50px;
    color: $c-999;
    vertical-align: middle;
}

.yzh-icon-arrow-up:before{
    font-family: 'yzhIcon';
    content: '\e632';
    font-size: 14px;
    color: $info;
    margin-right: 10px;
    vertical-align: middle;
    cursor: pointer;
}
.yzh-icon-arrow-down:before{
    font-family: 'yzhIcon'; 
    content: '\e633';
    font-size:14px;
    color: $info;
    vertical-align: middle;
    cursor: pointer;
}
.yzh-icon-disable::before{
    font-family: 'yzhIcon'; 
    content: '\e634';
    font-size:14px;
    color: $c-999;
    margin-right: 10px;
    vertical-align: middle;
    cursor:no-drop;
}
.yzh-icon-triangle-down::before{
    font-family: 'yzhIconCommon'; 
    content: '\e625';
    font-size:14px;
    color: $c-999;
    margin-right: 10px;
    vertical-align: middle;
    cursor:pointer;
}
.yzh-icon-triangle-up::before{
    font-family: 'yzhIconCommon'; 
    content: '\e623';
    font-size:14px;
    color: $c-999;
    margin-right: 10px;
    vertical-align: middle;
    cursor:pointer;
}
// 编辑
.yzh-icon-edit::before{
    font-family: 'yzhIconCommon';
    content: '\e638';
    font-size:18px;
    color: $fff;
    margin-right: 10px;
    vertical-align: middle;
    cursor:pointer;
}