/* @import '~antd/dist/antd.min.css'; */
/* @import '~antd/dist/antd.css'; */
@import '@ant-design/pro-form/dist/form.css';
@import '@ant-design/pro-table/dist/table.css';
@import '@ant-design/pro-card/dist/card.css';
@import '~antd/dist/antd.variable.min.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
