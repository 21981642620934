.#{$menu-prefix-cls} {
    // dark theme
    &.#{$menu-prefix-cls}-dark,
    &-dark &-sub,
    &.#{$menu-prefix-cls}-dark &-sub {
      color: $menu-dark-color;
      background: #14181F;
      .#{$menu-prefix-cls}-submenu-title
      .#{$menu-prefix-cls}-submenu-arrow {
        opacity: 0.45;
        transition: all 0.3s;
        &::after,
        &::before {
          background: $menu-dark-arrow-color;
        }
      }
    }
  
    &-dark.#{$menu-prefix-cls}-submenu-popup {
      background: transparent;
    }
  
    &-dark &-inline.#{$menu-prefix-cls}-sub {
    //   background: $menu-dark-inline-submenu-bg;
        background: rgba(0, 0, 0, 0.2);
    }
  
    &-dark.#{$menu-prefix-cls}-horizontal {
      border-bottom: 0;
    }
  
    &-dark.#{$menu-prefix-cls}-horizontal > &-item,
    &-dark.#{$menu-prefix-cls}-horizontal > &-submenu {
      top: 0;
      margin-top: 0;
      padding: $menu-item-padding;
      border-color: #14181F;
      border-bottom: 0;
    }
  
    &-dark.#{$menu-prefix-cls}-horizontal > &-item:hover {
      background-color: $menu-dark-item-active-bg;
    }
  
    &-dark.#{$menu-prefix-cls}-horizontal > &-item > a::before {
      bottom: 0;
    }
  
    &-dark &-item,
    &-dark &-item-group-title,
    &-dark &-item > a,
    &-dark &-item > span > a {
      color: $menu-dark-color;
    }
  
    &-dark.#{$menu-prefix-cls}-inline,
    &-dark.#{$menu-prefix-cls}-vertical,
    &-dark.#{$menu-prefix-cls}-vertical-left,
    &-dark.#{$menu-prefix-cls}-vertical-right {
      border-right: 0;
    }
  
    &-dark.#{$menu-prefix-cls}-inline &-item,
    &-dark.#{$menu-prefix-cls}-vertical &-item,
    &-dark.#{$menu-prefix-cls}-vertical-left &-item,
    &-dark.#{$menu-prefix-cls}-vertical-right &-item {
      left: 0;
      margin-left: 0;
      border-right: 0;
      &::after {
        border-right: 0;
      }
    }
  
    &-dark.#{$menu-prefix-cls}-inline &-item,
    &-dark.#{$menu-prefix-cls}-inline &-submenu-title {
      width: 100%;
    }
  
    &-dark &-item:hover {
        color: $menu-dark-item-hover-color;
        > a,
        > span > a {
            color: $menu-dark-item-hover-color;
        }
        background-color: transparent;
    }
    &-dark &-item-active,
    &-dark &-submenu-active,
    &-dark &-submenu-selected,
    &-dark &-submenu-title:hover {
      color: $menu-dark-highlight-color;
      background-color: transparent;
      > a,
      > span > a {
        color: $menu-dark-highlight-color;
      }
      > .#{$menu-prefix-cls}-submenu-title {
        > .#{$menu-prefix-cls}-submenu-arrow {
          opacity: 1;
          &::after,
          &::before {
            background: $menu-dark-highlight-color;
          }
        }
      }
    }
    &-dark &-submenu-open &-submenu-title {
        // background-color: rgba(0, 0, 0, 0.5);
        background-color: $menu-dark-item-active-bg;
    }
    // &-dark &-item:hover {
    //   background-color: $menu-dark-item-hover-bg;
    // }
  
    &-dark.#{$menu-prefix-cls}-dark:not(&-horizontal) &-item-selected {
      background-color: $menu-dark-item-active-bg;
    //   background-color: rgba(0, 0, 0, 0.5);
    }
  
    &-dark &-item-selected {
      color: $menu-dark-highlight-color;
      border-right: 0;
      &::after {
        border-right: 0;
      }
      > a,
      > span > a,
      > a:hover,
      > span > a:hover {
        color: $menu-dark-highlight-color;
      }
  
      .#{$menu-prefix-cls}-item-icon,
      .#{iconfont-css-prefix} {
        color: $menu-dark-selected-item-icon-color;
        + span,
        +span > a{
          color: $menu-dark-selected-item-text-color;
        }
      }
    }
  
    &.#{$menu-prefix-cls}-dark &-item-selected,
    &-submenu-popup.#{$menu-prefix-cls}-dark &-item-selected {
      background-color: $menu-dark-item-active-bg;
    }
  
    // Disabled state sets text to dark gray and nukes hover/tab effects
    &-dark &-item-disabled,
    &-dark &-submenu-disabled {
      &,
      > a,
      > span > a {
        color: $disabled-color-dark !important;
        opacity: 0.8;
      }
      > .#{$menu-prefix-cls}-submenu-title {
        color: $disabled-color-dark !important;
        > .#{$menu-prefix-cls}-submenu-arrow {
          &::before,
          &::after {
            background: $disabled-color-dark !important;
          }
        }
      }
    }

    // 修改公共antd样式
    &-inline &-item:not(:last-child),
    &-vertical-left &-item:not(:last-child),
    &-vertical-right &-item:not(:last-child),
    &-vertical &-item:not(:last-child) {
        margin-bottom: 0;
    }
    &-inline &-item,
    &-inline &-submenu-title,
    &-vertical-left &-item,
    &-vertical-left &-submenu-title,
    &-vertical-right &-item,
    &-vertical-right &-submenu-title,
    &-vertical &-item,
    &-vertical &-submenu-title {
        margin: 0;
    }
    &-sub.#{$menu-prefix-cls}-inline>&-item,
    &-sub.#{$menu-prefix-cls}-inline>&-submenu>&-submenu-title,
    &-inline>&-item,
    &-inline>&-submenu>&-submenu-title,
    &-vertical-left>&-item,
    &-vertical-left>&-submenu>&-submenu-title,
    &-vertical-right>&-item,
    &-vertical-right>&-submenu>&-submenu-title,
    &-vertical>&-item,
    &-vertical>&-submenu>&-submenu-title {
        height: 46px;
        line-height: 46px;
        background: transparent;
    }
    &-dark &-item-active,
    &-dark &-submenu-active,
    &-dark &-submenu-open,
    &-dark &-submenu-selected {
        .#{$menu-prefix-cls}-submenu-title {
            color: $menu-dark-highlight-color;
            .#{$menu-prefix-cls}-submenu-arrow {
                opacity: 1;
                &::before,
                &::after {
                  background: $menu-dark-highlight-color;
                }
            }
        }
    }
    &-dark &-item:hover,
    &-dark &-submenu-title:hover {
        color: $menu-dark-item-hover-color;
        >span,
        >span a {
            color: $menu-dark-item-hover-color;
        }
        .#{$menu-prefix-cls}-submenu-arrow {
            opacity: 1;
            &::before,
            &::after {
                background: $menu-dark-item-hover-color;
            }
        }
    }
}
.#{$layout-prefix-cls} {
    &-sider {
        z-index: 1000;
        &-dark {
              border-color: #14181F;
        }
    }
    &-header {
        background: $header-dark-bg;
        color: $header-dark-color;
        .anticon-down,
        .username {
            color: $header-dark-color;
        }
        .operation-list {
            .ant-btn {
                color: $header-dark-color;
                // &:hover {
                //     color: $primary;
                // }
            }
        }
    }
}
.ant-layout {
    background: $f8;
    &-header {
        box-shadow: 0 2px 10px 0 rgb(7 0 2 / 4%);
        position: relative;
        z-index: 10;
    }
}
.ant-tabs {
    &-nav {
        background-color: $white;
        margin-bottom: 0 !important;
    }
    &-card>&-nav &-tab,
    &-card>div>&-nav &-tab {
        background: $white;
        border: 0;
    }
    &-card>&-nav &-tab-active,
    &-card>div>&-nav &-tab-active {
        background: $f8;
    }
    &-extra-content {
        padding: 0 20px;
        line-height: 40px;
        height: 40px;
        border-left: 1px solid $e8;
        cursor: pointer;
        .anticon-down {
            font-size: $fontS14 !important;
            color: $c-666;
        }
    }
}
.ant-empty {
    .ant-empty-image {
        height: auto;
        .ant-image-img {
            height: 100px;
        }
    }
}
body.is-black {
    filter: grayscale(1);
}
.ant-pro-table {
    .ant-card {
        &-body {
            padding-bottom: 0;
        }
    }
    .ant-pro-card-body {
        padding-bottom: 0;
    }
    .ant-pro-table-search {
        margin-bottom: 0;
        padding-top: 16px;
        padding-bottom: 7px
    }
    .ant-pro-card-body{
      padding:0 16px;
    }
    .yzh-table-extra{
      padding: 16px 16px 0 16px;
    }
    // .ant-table {
    //     min-height: calc(100% - 420px);
    // }
    .ant-empty {
        margin: 100px 0;
        .ant-image-img {
            height: 100px;
        }
    }
    .ant-spin-nested-loading>div>.ant-spin {
        max-height: 100%;
    }
    &.yzh-table {
        .ant-form-item {
            margin-bottom: 10px;
            &-label {
                padding-right: 8px;
            }
        }
        .selection-table
        .ant-table-footer {
            position: absolute;
            left: auto;
            bottom: -55px;
            padding-left: 22px;
            background: $white;
            border: none;
        }
    }
}
// .ant-pro-form-query-filter {
//     .ant-row-start >.ant-col:last-child {
//         margin-left: 30px;
//         text-align: left !important;
//     }
// }
.ant-modal-confirm .ant-modal-body {
    padding: 20px;
}
.ant-form-item {
    margin-bottom: 16px;
    &-label {
        padding-right: 8px;
    }
}
.ant-modal-body {
    // padding: 40px 24px;
    min-height: 120px;
}
.ant-pro-table-list-toolbar-container {
    padding: 20px 0 0;
}
.ant-table-thead>tr>th {
    color: $c-333;
    font-weight: bold;
}
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-tbody>tr>td,
.ant-table.ant-table-middle .ant-table-thead>tr>th,
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle tfoot>tr>td,
.ant-table.ant-table-middle tfoot>tr>th {
    padding: 12px 20px;
}
.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
    background: transparent;
    color: #ccc;
    border-color: #ccc;
}
.ant-btn-link[disabled], .ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus, .ant-btn-link[disabled]:hover {
    border-color: transparent;
}
.ant-popover-message>.anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon,
.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon {
    color: $primary;
}
.ant-tooltip {
    &-content {
        width: 400px;
        max-height: 400px;
        overflow-y: auto;
    }
}
.ant-table-pagination.ant-pagination {
    margin: 16px 0;
}
.theme-dialog {
  left: 0;
  .ant-drawer-body {
      padding: 24px;
  }
  .ant-drawer-content-wrapper {
      width: 360px !important;
  }
}