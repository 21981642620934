@charset "utf-8";

.yzh-layout {
    height: 100vh;
    background-color: $f8;
    overflow: hidden;
    // overflow-x: auto;
}

.yzh-aside::-webkit-scrollbar {
    display: none;
}

.yzh-aside {
    overflow: auto;
    height: 100vh;
    // box-shadow: 5px 0 20px $border;
    z-index: 9999;
    position: relative;
    scrollbar-width: none;

    .yzh-logo {
        width: 72px;
        height: 72px;
        background: #14181F;
        text-align: center;
    }

    .logo {
        margin-top: 5px;
        font-size: 18px;
        color: #fff;
    }

    .yzh-Menu {
        font-size: 14px;
        text-align: center;
        color: #fff;
        z-index: 10000;
        cursor: pointer;

        overflow-y: auto;

        dl {
            width: 72px;
            height: 72px;
            cursor: pointer;
            margin: 0;

            dt {
                padding-top: 14px;
            }

            dd {
                font-size: 14px;
                font-weight: 600;
                margin-top: 4px;
            }

        }

        .active {
            background-color: #F76700;
            color: #fff;

            .logo {
                color: #fff;
            }
        }

        .inActive:hover {
            //  background-color: #F76700;
            color: #F76700;

            .logo {
                color: #F76700;
            }
        }
    }
}

.ant-drawer-body {
    padding: 0;
}

.subMenuList {
    padding: 0;

    .title {
        font-size: 14px;
        color: #8c8c8c;
        padding-left: 16px;
        margin-top: 32px;
    }

    .menuList {
        width: 100%;
        margin-top: 12px;
        cursor: pointer;

        .activeMenu {
            background: rgba(247, 103, 0, 0.12);
            color: #F76700;
        }

        .inActiveMenu:hover {
            color: #F76700;
        }

        span {
            display: inline-block;
            font-size: 14px;
            color: #232323;
            width: 176px;
            height: 40px;
            padding-left: 32px;
            line-height: 40px;
            margin-right: 32px;
        }
    }
}

.ant-drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 72px;
    z-index: 500;
    pointer-events: none;
}

.ant-drawer-content-wrapper {
    width: 640px !important;
}

.yzh-header {
    width: 100%;
    background-color: $white;

    .trigger {
        padding: 0 24px;
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;
        // color: var(--header-text-color);
        // :hover {
        //     color: $primary;
        // }
    }

    .user-info {
        // min-width: 240px;
        display: flex;
        align-items: center;
        // color: var(--header-text-color);
        justify-content: space-around;

        .dropdown-link {
            cursor: pointer;
            // color: var(--header-text-color);
            display: flex;
            align-items: center;
            justify-content: space-around;

            .username {
                margin: 0 10px;
            }

            // &:hover {
            //     .anticon,
            //     .ant-avatar,
            //     .username {
            //         color: $primary;
            //     }
            // }
        }

        .anticon {
            font-size: 18px;
            cursor: pointer;

            // &:hover {
            //     color: $primary;
            // }
            &.anticon-down {
                font-size: 14px;
            }
        }
    }
}

.yzh-content {
    // min-height: calc(100vh - 64px);
    // margin-top: 20px;
    margin: 16px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 0 20px $border;
    border-radius: $box-radius;
    background-color: $white;
}

.yzh-main {
    // height: calc(100% - 20px);
    // overflow: hidden;
    // overflow-x: hidden;
    background-color: $white;
    height: 100%;
    position: relative;

    .el-main {
        margin: 0;
        height: 100%;
    }

    .ant-spin-container {
        height: 100%;
    }

    .ant-spin-nested-loading>div>.ant-spin {
        max-height: 100%;
    }

    .iframe-pages {
        // background-color: transparent;
        overflow: hidden;

        .ant-spin.ant-spin-spinning {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: $fff;

            .ant-spin-text {
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }
}

.yzh-tabs-nav {
    .ant-tabs-nav {
        height: 40px;
    }
}

.yzh-breadcrumb {
    line-height: 64px;

    >span:last-child,
    .ant-breadcrumb-separator,
    >span:last-child a,
    a {
        color: $header-dark-color !important;
    }

    .ant-breadcrumb-overlay-link {
        line-height: 64px;
        display: inline-block;
    }

    .ant-breadcrumb-overlay-link {
        .anticon-down {
            margin-left: 10px;
        }
    }

    &-dropdown {
        .ant-dropdown-menu {
            max-height: 400px;
            overflow: hidden;
            overflow-y: auto;

            &-item {
                color: $c-666;

                .ant-menu-item {
                    .anticon-close {
                        visibility: hidden;

                        &:hover {
                            color: $c-333;
                        }
                    }

                    &:hover {

                        // color: $c-333;
                        .anticon-close {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.yzh-footer {
    width: 100%;
}

.yzh-error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

#nprogress .bar {
    background: $primary;
    height: 2px !important;
}

#yzh-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 20px;
    flex-direction: column;

    .ant-spin-text {
        margin-top: 10px;
        font-size: 14px;
    }
}

.yzh-modal {

    .ant-modal-footer,
    .ant-modal-header {
        padding: 10px 24px;
    }

    .ant-modal-footer {
        // text-align: center;
        text-align: right;
    }

    .ant-modal-close {
        .ant-modal-close-x {
            line-height: 43px;
            height: 43px;
            width: 43px;
        }
    }
}

.yzh-ie-tips {
    background-color: gold;
    color: $c-666;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    position: fixed;
    z-index: 9999;
    width: 100%;

    .content {
        padding-right: 100px;
    }

    .down-btn {
        background-color: $fff;
        padding: 2px 8px;
        border-radius: 3px;
    }

    .close {
        right: 30px;
        font-size: 20px;
        margin-left: 20px;
        cursor: pointer;
        color: $fff;
    }
}

.grid-list {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 10px;

    &-item {
        width: 200px;
        height: auto;
        padding: 0 !important;
        // background-color: skyblue;
    }
}

.ka-wrapper,
.ka-content {
    height: 100%;
    width: 100%;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}